<template>
  <div class="main nav-main flex align-center">
    <div v-for="(item) in $store.state.menu" :key="item.cat_id" class="nav-item flex-1 flex-column align-center pointer" @click="handleClick(item.cat_id)">{{ item.name }}</div>
  </div>
</template>
<script>
export default {
  name: 'NavList',
  data() {
    return {};
  },
  methods: {
    handleClick(cid) {
      const routerName = {
        '0': 'HomePage',
        '1': 'ModelSchool',
        '4': 'Benchmark',
        '10': 'Benchmark',
        '14': 'Benchmark',
      };
      this.$router.push({ name: routerName[cid], query: { cid } });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-main{
  height: 100px;
  .nav-item{
    margin-left: 55px;
    width: 195px;
    text-align: center;
    font-size: 30px;
    color: #fff;
    font-weight: 900;
  }
  .nav-item:nth-child(1){
    margin-left: 0;
  }
}
</style>
