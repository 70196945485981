<template>
  <div :class="['swiper', classify]">
    <div class="swiper-wrapper">
      <div v-for="(item, index) in list" :key="index" class="swiper-slide slider1">
        <img :src="item.b_img" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import SwiperCore, { Autoplay } from 'swiper/core';
SwiperCore.use([Autoplay]);
import 'swiper/css';
export default {
  name: 'SwiperList',
  props: {
    list: {
      type: Array,
      default: () => ([
        { url: require('@/assets/image/banner1.jpg') },
        { url: require('@/assets/image/banner2.jpg') },
      ]),
    },
    classify: {
      type: String,
      default: 'swiper1',
    },
  },
  data() {
    return {};
  },
  mounted() {
    new Swiper(`.${this.classify}`, {
      speed: 3000,
      loop: true,
      // rewind: true,
      lazy: true,
      loopAdditionalSlides: 2,
      autoplay: {
        delay: 1500,
        disableOnInteraction: false,
      },
    });
  },
};
</script>

<style lang="scss" scoped>
.swiper{
  width: 100%;
  height: 100%;
  font-size: 0;
}
//.swiper1{
//  height: 500px;
//}
.swiper-slide{
  height: auto;
  color: #fff;
  font-size: 0;
  img{
    width: 100%;
    height: 100%;
  }
}
</style>
