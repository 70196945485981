import axios from 'axios';
// 错误打印及提示
const service = axios.create({
  baseURL: 'https://axgsc.brohey.cn/api',
});
service.interceptors.response.use(
  response => {
    // 自定义配置项
    const conf = response.config.options || {};
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data;
    // 这个状态码是和后端约定的
    const { code } = dataAxios;
    // 根据 code 进行判断
    switch (code) {
      // 			// 如果没有 code 代表这不是项目后端开发的接口
      case undefined:
        return dataAxios;

        // 有 code 代表这是一个后端接口 可以进行进一步的判断
        // code === 0 代表没有错误
      case 0:
        return dataAxios.data;
      default:
        return Promise.reject(dataAxios);
    }
  },
);

export default service;
