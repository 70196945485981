<template>
  <div class="box">
    <div v-if="showTitle" class="flex align-center justify-between title">
      <span>{{ title }}</span>
      <span class="pointer" @click="handleMore">{{ moreText }}</span>
    </div>
    <ul>
      <li v-for="item in list" :key="item.id" class="flex align-center justify-between item-list pointer" @click="handleList(item)">
        <div class="flex align-center">
          <i />
          <span class="text text-cut">{{ item.a_title }}</span>
        </div>
        <span class="date">{{ item.created_at }}</span>
      </li>
    </ul>
    <slot v-if="$slots.page" name="page" />
  </div>
</template>

<script>
export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '制度文件',
    },
    moreText: {
      type: String,
      default: '更多>>',
    },
    list: {
      type: Array,
      default: () => [
        {
          id: 1,
          text: '喜报/我校在全国全省第三批新时代高校',
          date: '2022-8-20',
        },
        {
          id: 2,
          text: '喜报/我校在全国全省第三批新时代高校2',
          date: '2022-8-20',
        },
        {
          id: 3,
          text: '喜报/我校在全国全省第三批新时代高校2',
          date: '2022-8-20',
        },
        {
          id: 4,
          text: '喜报/我校在全国全省第三批新时代高校2',
          date: '2022-8-20',
        },
        {
          id: 5,
          text: '喜报/我校在全国全省第三批新时代高校2',
          date: '2022-8-20',
        },
        {
          id: 6,
          text: '喜报/我校在全国全省第三批新时代高校2',
          date: '2022-8-20',
        },
        {
          id: 7,
          text: '喜报/我校在全国全省第三批新时代高校2',
          date: '2022-8-20',
        },
        {
          id: 8,
          text: '喜报/我校在全国全省第三批新时代高校2',
          date: '2022-8-20',
        },
        {
          id: 9,
          text: '喜报/我校在全国全省第三批新时代高校2',
          date: '2022-8-20',
        },
        {
          id: 10,
          text: '喜报/我校在全国全省第三批新时代高校2',
          date: '2022-8-20',
        },
      ],
    },
  },
  methods: {
    // 点击每一项
    handleList(item) {
      this.$emit('handleList', item);
    },
    // 点击更多
    handleMore() {
      this.$emit('moreClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.box{
  box-sizing: border-box;
  padding: 23px 0 24px 23px;
  border: 1px solid #BBBBBB;
  width: 100%;
  height: 415px;
  min-height: 415px;
  background: #fff;
  ul{
    padding-right: 24px;
    max-height: 348px;
    overflow: auto;
  }
  .title{
    padding-right: 24px;
    border-bottom: 2px solid #bbb;
    span{
      color: $color-danger;
      font-weight: 900;
    }
    span:nth-child(1){
      font-size: 26px;
      border-bottom: 4px solid $color-danger;
      padding-bottom: 3px;
    }
    span:nth-child(2){
      font-size: 18px;
    }
  }
  .item-list{
    margin-top: 20px;
    padding-bottom: 4px;
    border-bottom: 1px dashed #bbb;
    .text{
      font-size: 18px;
      display: inline-block;
      width: 380px;
    }
    .date{
      margin-right: 20px;
      font-size: 14px;
      color: #101010;
    }
    i{
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #eee;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
}
</style>
