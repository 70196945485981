<template>
  <div class="box">
    <div class="title align-center flex justify-center">{{ data.a_title }}</div>
    <div class="content" v-html="data.content" />
    <div v-if="!data.filename" class="flex file-button-wrap">
      <!--      https://view.officeapps.live.com/op/view.aspx?src=-->
      <a class="" :href="data.file" target="_blank">{{ data.filename }}</a>
    </div>
    <div class="view flex align-center justify-end">
      <img src="@/assets/image/views.png" alt="">
      <span>已阅读 {{ data.count }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.box{
  padding-bottom: 26px;
  border: 1px solid #bbb;
  background: #fff;
  .title{
    box-sizing: border-box;
    padding: 0 20px;
    height: 110px;
    font-size: 30px;
    border-bottom: 1px solid $color-danger;
  }
  .content{
    padding: 32px 90px 30px;
    line-height: 30px;
    font-size: 20px;
  }
  .view{
    font-size: 14px;
    padding-right: 20px;
    color: #bbb;
    img{
      margin-right: 7px;
    }
  }
  .file-button-wrap{
    padding-left: 90px;
  }
  .download{
    box-sizing: border-box;
    width: 205px;
    height: 54px;
    line-height: 54px;
    text-align: center;
    font-size: 20px;
    border-radius: 4px;
    color: #101010;
    text-decoration: none;
    border: 1px solid #bbb;
  }
}
</style>
