import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Swiper from "@/components/Swiper";
import NavList from "@/components/NavList";
import TitleList from "@/components/TitleList";
import ArticleDetail from "@/components/ArticleDetail";

const components = {
	Header,
	Footer,
	Swiper,
	NavList,
	TitleList,
	ArticleDetail,
};

export default function (Vue){
	Object.keys(components).forEach(key => {
		Vue.component(key, components[key]);
	});
};

