import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/api/request';
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    footer: [],
	  index_banner: [],
	  menu: [],
	  top_banner: [],
	  isShow: false,
    bottom_banner: [],
  },
  mutations: {
  	SET_FOOTER(state, footer) {
		  state.footer = footer;
	  },
  	SET_INDEX_BANNER(state, index_banner) {
		  state.index_banner = index_banner;
	  },
  	SET_MENU(state, menu) {
		  state.menu = menu;
	  },
  	SET_TOP_BANMER(state, top_banner) {
		  state.top_banner = top_banner;
	  },
    SET_SHOW(state, flag) {
      state.isShow = flag;
    },
    SET_BOTTOM_BANNER(state, bottom_banner) {
      state.bottom_banner = bottom_banner;
    },
  },
  actions: {
    init ({ commit }) {
      axios.post('/pc/sys/config').then(res => {
        commit('SET_FOOTER', res.footer);
        commit('SET_INDEX_BANNER', res.index_banner);
        commit('SET_MENU', res.menu);
        commit('SET_TOP_BANMER', res.top_banner);
        commit('SET_BOTTOM_BANNER', res.bottom_banner);
        commit('SET_SHOW', 1);
      });
    },
  },
});
export default store;
