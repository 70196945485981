<template>
  <div>
    我是头部是价收到
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

</style>
