<template>
  <div class="footer">
    <div class="main flex align-center justify-between">
      <div class="flex align-center">
        <div class="link-title">友情链接：</div>
        <a v-for="item in $store.state.footer" :key="item.id" :href="item.f_url">{{ item.f_name }}</a>
      </div>
      <div class="tip">
        <p>石家庄信息工程职业学院党建“双创”成果专题网</p>
        <p>中共石家庄信息工程职业学院委员会组宣部主办</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer{
  margin-top: 52px;
  background: $color-danger;
  height: 91px;
  .main{
    height: 91px;
  }
}
.link-title{
  font-weight: bold;
  font-size: 18px;
  color: #fff;
}
a{
  margin-right: 18px;
  color: #fff;
  font-size: 20px;
  text-decoration: none;
}
.tip{
  font-size: 12px;
  color: #fff;
  p{
    margin: 0;
  }
}
</style>
