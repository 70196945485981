import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const router = new VueRouter({
  routes: [{
    path: '/', // 主页
    name: 'HomePage',
    component: () => import('@/views/home'),
    children: [],
  }, {
    path: '/sub-page', // 主页文章列表
    name: 'SubPage',
    component: () => import('@/views/sub-page'),
  }, {
    path: '/article-details', // 主页文章详情
    name: 'ArticleDetails',
    component: () => import('@/views/article-details'),
  }, {
    path: '/model-school', // 示范高校
    name: 'ModelSchool',
    component: () => import('@/views/model-school'),
  }, {
    path: '/model-school-details', // 示范高校-详情页
    name: 'ModelSchoolDetails',
    component: () => import('@/views/model-school-detail'),
  }, {
    path: '/benchmark', // 标杆院系
    name: 'Benchmark',
    component: () => import('@/views/benchmark'),
  }, {
    path: '/benchmark-list', // 标杆院系 - 列表
    name: 'BenchmarkList',
    component: () => import('@/views/benchmark-list'),
  }, {
    path: '/template', // 样板支部
    name: 'Template',
    component: () => import('@/views/template'),
  }, {
    path: '/template-list', // 样板支部 - 列表
    name: 'TemplateList',
    component: () => import('@/views/template-list'),
  }, {
    path: '/lead', // 样板支部
    name: 'Lead',
    component: () => import('@/views/lead'),
  }, {
    path: '/lead-list', // 样板支部 - 列表
    name: 'LeadList',
    component: () => import('@/views/lead-list'),
  }, {
    path: '/list-details', // 样板支部 - 列表
    name: 'ListDetails',
    component: () => import('@/views/list-details'),
  }],
});

export default router;
