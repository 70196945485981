<template>
  <div v-if="$store.state.isShow" id="app">
    <Swiper classify="swiper1" :list="$store.state.top_banner" />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import axios from '@/api/request';
export default {
  name: 'App',
  components: {},
  data() {
    return {};
  },
  created() {
    this.$store.dispatch('init');
  },
};
</script>

<style lang="scss">
@import '@/styles/base.scss';
@import '@/styles/colors.scss';
</style>
